import { API_URL } from "./config";

/**
 *
 * @param {String} memberEmail
 * @returns true or false depending on if user already exists or not
 */
/* export const checkIfMemberExists = async (memberEmail) => {
  try {
    const response = await fetch(`${API_URL}/api/validate/${memberEmail}`, {
      method: "GET",
      mode: "cors",
      headers: {
        "content-type": "application/json",
      },
    });
    const responseJson = await response.json();
    console.log(responseJson);
    if (responseJson.status === 409) {
      return true;
    } else {
      console.log("Member does not exist. returning false");
      return false;
    }
  } catch (error) {
    console.log(error);
  }
}; */

/**
 * @param {*} data
 * @returns true or false depnding on if confirmation was sent or not
 */
export const sendConfirmationMail = async (data) => {
  try {
    const resp = await fetch(`${API_URL}/api/email`, {
      method: "POST",
      mode: "cors",
      headers: {
        "content-type": "application/json",
      },
      body: JSON.stringify(data),
    });

    const respJson = await resp.json();
    console.log(respJson);

    if (respJson.status === 201 || respJson.status === 202) {
      return true;
    }
    if (respJson.status === 409) {
      return false;
    }
  } catch (err) {
    console.error(err);
  }
};

/**
 *
 * @param {*} data
 * @returns true or false depnding on if member was created or not
 */
export const createMember = async (data) => {
  try {
    const resp = await fetch(`${API_URL}/api/member`, {
      method: "POST",
      mode: "cors",
      headers: {
        "content-type": "application/json",
      },
      body: JSON.stringify(data),
    });

    const respJson = await resp.json();
    console.log(respJson);

    if (respJson.status === 201) {
      return true;
    }
    if (respJson.status === 409) {
      return false;
    }
  } catch (err) {
    console.error(err);
  }
};
