import React, { useState } from "react";
import styled from "styled-components";
import {
  Form,
  Input,
  Row,
  Col,
  Checkbox,
  Button,
  Radio,
  Popover,
  Space,
  Alert,
  message,
  Typography,
} from "antd";
import "antd/dist/antd.css";
import GdprContent from "../GdprContent";
import { sendConfirmationMail, checkIfMemberExists } from "../lib/helpers";
import Loading from "./loading";

// Debug test object
/* const memberObject = {
  agreement: true,
  email: "test5@straycat.se",
  firstName: "Simon",
  lastName: "Solberg",
  membership: "lifetime",
  mobile: "0703100453",
  payMethod: "swish",
  personNumber: "199706209419",
  phone: "0703100453",
  subMembership: [
    {
      membershipType: 1,
      membershipName: "ungsvenskarna",
      active: true,
    },
  ],
}; */

const MemberForm = () => {
  const [isFemale, setIsFemale] = useState(false);
  const [isUnder30, setIsUnder30] = useState(false);
  const [showSuccessMessage, setShowSuccessMessage] = useState(false);
  const [form] = Form.useForm();
  const [isLoading, setIsLoading] = useState(false);

  const onFinish = async (values) => {
    setIsLoading(true);
    const memberships = [];
    // If checkmembership is required, use this promise before proceeding
    /* const memberExists = await checkIfMemberExists(values.email);
    if (!memberExists) { */

    // Add submembership info
    for (const membership of values.subMembership) {
      if (membership === "sdu" || membership === "sd-kvinnor") {
        const newObj = {
          membershipType: 1,
          membershipName: membership,
          active: true,
        };
        memberships.push(newObj);
      }
    }
    values.subMembership = memberships;

    // Add payAmount based on membership
    let payAmount;
    if (values.membership === "lifetime") {
      payAmount = 2000;
    } else {
      payAmount = 200;
    }

    // Send confirmation email via middleware API and then reset form fields
    const response = await sendConfirmationMail({ ...values, payAmount });
    setIsLoading(false);
    if (response) {
      form.resetFields();
      setShowSuccessMessage(true);
    } else {
      message.error("Något gick fel!");
    }
  };

  const onFinishFailed = (errorInfo) => {
    console.log("Failed:", errorInfo);
  };

  const onPersonNumberChange = () => {
    const pNum = form.getFieldValue("personNumber");
    if (pNum.length === 12) {
      const isUngsvensk = 2022 - pNum.substring(0, 4) < 30;
      const isSDkvinna = /* `${pNum}`.length > 11 && */ !(
        pNum.substring(10, 11) % 2
      );
      setIsFemale(isSDkvinna);
      setIsUnder30(isUngsvensk);

      form.setFieldsValue({
        subMembership: [isSDkvinna && "sd-kvinnor", isUngsvensk && "sdu"],
      });
    }
  };

  return (
    <>
      {showSuccessMessage && (
        <Alert
          message="Var vänlig verifiera din ansökan via e-postadressen som du angivit."
          type="success"
        />
      )}
      {!showSuccessMessage && !isLoading && (
        <Form
          name="basic"
          labelCol={{
            span: 24,
          }}
          wrapperCol={{
            span: 24,
          }}
          initialValues={{
            ungsvenskarna: true,
          }}
          form={form}
          onFinish={onFinish}
          onFinishFailed={onFinishFailed}
          autoComplete="off"
          layout="vertical"
        >
          <ItemContainer
            label="Förnamn"
            name="firstName"
            rules={[
              {
                required: true,
                message: "Fyll i fältet!",
              },
            ]}
          >
            <Input bordered={false} />
          </ItemContainer>
          <ItemContainer
            label="Efternamn"
            name="lastName"
            rules={[
              {
                required: true,
                message: "Fyll i fältet!",
              },
            ]}
          >
            <Input bordered={false} />
          </ItemContainer>
          <ItemContainer
            label="Personnummer"
            name="personNumber"
            tooltip="Ditt personnummer, utan bindesstreck, används för att identifiera dig. Numret skickas aldrig oskyddat över nätet. Om du är bosatt utomlands är du välkommen att mejla medlem@sd.se för att erhålla ett medlemskap hos Sverigedemokraterna"
            rules={[
              {
                required: true,
                message: "Fyll i fältet!",
              },
            ]}
          >
            <Input
              onChange={onPersonNumberChange}
              placeholder="ÅÅÅÅMMDDXXXX"
              bordered={false}
            />
          </ItemContainer>
          <ItemContainer
            label="E-post"
            name="email"
            rules={[
              {
                required: true,
                message: "Fyll i fältet!",
                type: "email",
              },
            ]}
          >
            <Input bordered={false} />
          </ItemContainer>
          <ItemContainer
            label="Mobilnummer"
            name="mobile"
            rules={[
              {
                required: true,
                message: "Fyll i fältet!",
              },
            ]}
          >
            <Input bordered={false} />
          </ItemContainer>
          <ItemContainer
            label="Telefonnummer"
            name="phone"
            rules={[
              {
                required: false,
                message: "Fyll i fältet!",
              },
            ]}
          >
            <Input bordered={false} />
          </ItemContainer>
          <ItemContainer
            name="membership"
            label="Medlemskap"
            rules={[{ required: false, message: "Välj en!" }]}
          >
            <Radio.Group>
              <Space>
                <Radio.Button value="yearly">Årsvis, 200 kr</Radio.Button>
                <Radio.Button value="lifetime">Ständigt, 2000 kr</Radio.Button>
              </Space>
              ∑
            </Radio.Group>
          </ItemContainer>

          <ItemContainer name="subMembership" label="Förbund">
            <Checkbox.Group>
              <Row>
                <Col span={16}>
                  <Checkbox
                    value="sdu"
                    disabled={!isUnder30}
                    style={{ lineHeight: "32px" }}
                  >
                    Ungsvenskarna
                  </Checkbox>
                </Col>
                <Col span={16}>
                  <Checkbox
                    value="sd-kvinnor"
                    disabled={!isFemale}
                    style={{ lineHeight: "32px" }}
                  >
                    SD-Kvinnor
                  </Checkbox>
                </Col>
              </Row>
            </Checkbox.Group>
          </ItemContainer>
          <ItemContainer
            name="payMethod"
            label="Betalning"
            rules={[{ required: false, message: "Välj en!" }]}
          >
            <Radio.Group>
              <Space>
                <Radio.Button value="payCard">Kort</Radio.Button>
                <Radio.Button value="paySwish">Swish</Radio.Button>
                <Radio.Button value="payEmail">
                  Banköverföring (e-post)
                </Radio.Button>
                <Radio.Button value="payAvi">Banköverföring (avi)</Radio.Button>
              </Space>
            </Radio.Group>
          </ItemContainer>

          <ItemContainer
            name="agreement"
            valuePropName="checked"
            rules={[
              {
                validator: (_, value) =>
                  value
                    ? Promise.resolve()
                    : Promise.reject(
                        new Error(
                          "Du måste godkänna behandlingen av personuppgifter."
                        )
                      ),
              },
            ]}
          >
            <Space direction="vertical" size={"middle"}>
              <Checkbox>
                Jag har läst och godkänt behandlingen av mina{" "}
                <Popover
                  content={GdprContent}
                  title="Behandling av personuppgifter"
                  trigger="click"
                >
                  <a href="#">personuppgifter</a>.
                </Popover>
              </Checkbox>
              <Alert
                type="info"
                message={`Kontrollera att dina angivna uppgifter är korrekta och tryck på
                ansök, vänlig invänta bekräftelselänk på ansökan som mejlas till
                dig.`}
                style={{ whiteSpace: "normal" }}
              />
              <Button type="primary" htmlType="submit" size="large">
                Starta ansökan
              </Button>
              {/* Button that can be used to debug */}
              {/* <Button
                onClick={() => {
                  const doesExist = checkIfMemberExists("simon.solberg@sd.se");

                  if (doesExist)
                    alert(
                      "Found a person with the email address simon.solberg@sd.se."
                    );
                }}
              >
                Testa GET-request
              </Button> */}
            </Space>
          </ItemContainer>
        </Form>
      )}
      {isLoading && <Loading />}
    </>
  );
};

export default MemberForm;

const ItemContainer = styled(Form.Item)`
  background-color: #fafafa;
  padding: 1.5rem;
  border-radius: 0.3rem;
  width: calc(100% - 2rem);
  margin: 1rem auto;
`;
