import { Row, Spin, Col, Typography } from "antd";

const Loading = () => {
  return (
    <Row justify="center" align="middle">
      <Col>
        <div style={{ textAlign: "center" }}>
          <Spin />
          <Typography.Title>Var god vänta...</Typography.Title>
        </div>
      </Col>
    </Row>
  );
};

export default Loading;
