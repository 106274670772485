const GdprContent = (
  <div style={{ maxWidth: 500 }}>
    <p>
      Jag samtycker till att de personuppgifter som jag lämnat i formuläret ovan
      registreras och behandlas av Sverigedemokraterna. Uppgifterna behandlas
      konfidentiellt och med mycket hög säkerhet.
    </p>
    <p>
      Dina personuppgifter behöver hållas för att upprätta röstlängder och
      kallelser till medlemsmöten samt olika former av utskick. I dessa fall ges
      ditt distrikt, din kommunförening eller vår distributör tillgång till dina
      uppgifter. Vi betraktar medlemskap som fortlöpande, dvs du betraktas som
      medlem tills du begär utträde. För mer information:{" "}
      <a href="https://sd.se/integritetspolicy/">
        https://sd.se/integritetspolicy/
      </a>
    </p>
    <p>
      Ansvarig för behandlingen av personuppgifterna är Sverigedemokraterna.
    </p>
    <p>
      Önskar du besked om vilka uppgifter som behandlas om dig, har du rätt att
      en gång per år, kostnadsfritt, få information om detta genom att skicka en
      skriftlig begäran till:
    </p>
    <p>
      <i>
        Sverigedemokraterna<br></br>Medlem<br></br> Box 20085<br></br> 104 60
        Stockholm
      </i>
    </p>
    <p>
      eller via e-post till <a href="mailto:medlem@sd.se">medlem@sd.se</a>. Du
      har även rätt att begära ändring av felaktiga eller missvisande uppgifter.
    </p>
  </div>
);

export default GdprContent;