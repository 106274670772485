import { Routes, Route } from "react-router-dom";
import Confirm from "./components/confirm";
import MemberForm from "./components/memberform";
import Layout from "./hoc/layout";
import "./App.css";

const App = () => {
  return (
    <div>
      <Routes>
        <Route path="/" element={<Layout />}>
          <Route index element={<MemberForm />} />
          <Route path="/bekrafta/:id" element={<Confirm />} />
        </Route>
      </Routes>
    </div>
  );
};

export default App;
