import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { useParams } from "react-router-dom";
import { API_URL } from "../lib/config";
import { createMember } from "../lib/helpers";
import Loading from "./loading";

const Confirm = () => {
  const [confirming, setConfirming] = useState(true);
  const [alreadyConfirmed, setalreadyConfirmed] = useState(false);
  const { id } = useParams();

  useEffect(() => {
    const fetchData = async () => {
      const res = await fetch(`${API_URL}/api/email/confirm/${id}`);
      const data = await res.json();
      console.log(data);
      if (data.status === 201 || data.status === 202) {
        const memberGotCreated = await createMember(data.user);
        setConfirming(false);
      }
      if (data.status === 409) {
        setConfirming(false);
        setalreadyConfirmed(true);
      }
    };

    fetchData().catch(console.error);
  }, [id]);

  return (
    <div className="confirm">
      {confirming ? (
        <Loading />
      ) : (
        <Link to="/" style={{ textAlign: "center" }}>
          <p>
            {!alreadyConfirmed
              ? `Tack för din ansökan! Du kommer att kontaktas via e-post inom kort.`
              : `Din ansökan har redan verifierats. Du kommer att kontaktas via e-post inom kort.`}
          </p>
        </Link>
      )}
    </div>
  );
};

export default Confirm;
