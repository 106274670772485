import React, { useEffect } from "react";

import { Row, Col } from "antd";
import { Outlet } from "react-router-dom";
import styled from "styled-components";
import { API_URL } from "../lib/config";

const Layout = () => {
  useEffect(() => {
    const wakeUp = async () => {
      await fetch(`${API_URL}/api/wake-up`);
    };
    wakeUp();
  }, []);

  return (
    <Main>
      <Row style={{ height: "100%" }}>
        <Container>
          <Row justify="center">
            <Col style={{ paddingTop: "2rem" }}>
              <Title>
                <mark>Medlemsansökan</mark>
                {/* <h5>Sverigedemokraterna</h5> */}
              </Title>
            </Col>
          </Row>
          <Outlet />
        </Container>
      </Row>
    </Main>
  );
};

export default Layout;

const Main = styled.div`
  height: 100vh;

  h1 {
    @media (max-width: 768px) {
      font-size: 28px;
    }
  }

  .ant-form-item-label {
    overflow: visible;

    label {
      color: #322667;
      font-weight: bold;
      font-size: 1.375rem;
      display: inline-block;
      width: 100%;
      text-align: left;
    }
  }

  .ant-input-borderless {
    border-bottom: 1px solid #322667;
  }

  @media screen and (min-width: 981px) {
    .ant-space-item {
      white-space: nowrap;
    }
  }

  .ant-btn-primary,
  .ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled) {
    background-color: #ffde00;
    color: #ff9c00;
    font-weight: bold;
    border: none;
  }

  .ant-radio-button-wrapper:hover {
    color: #ff9c00;
  }
`;

const Container = styled.div`
  @media screen and (min-width: 981px) {
    min-width: 600px;
    max-width: 600px;
  }
  @media screen and (max-width: 980px) {
    width: 100%;
    min-width: 100%;

    .ant-space {
      display: inline-block;
    }
  }
  margin: auto;
  color: #222222;

  a {
    color: #ff9c00;
    font-weight: bold;
  }
`;

const Title = styled.h1`
  font-weight: bold;
  margin-top: 1rem;
  text-align: center;

  mark {
    color: #ff9c00;
    background-color: #ffde00;
  }
`;
